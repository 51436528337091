<template>
  <div class="container">
    <div class="row text-center justify-content-center">
      <h1>Verifizierte JnRs</h1>
      <h4>Erhalte hier einen Überblick über alle JnRs die dir fehlen!</h4>
      <div class="col-lg-6 col-md-12">
        <div class="input-group mb-3">
          <input type="text" class="form-control " placeholder="Dein Nutzername" aria-label="Suchen"
                 aria-describedby="Suche" @input="search">
          <button class="btn btn-outline-warning" type="button" id="search" v-bind:disabled="buttonState" v-on:click="sendRequest()">Suchen</button>
        </div>
      </div>
      <div class="col-lg-9 col-md-12">
        <div v-if="!loaded && !empty" class="mt-5 text-center">
          <Loader></Loader>
        </div>
        <div v-if="empty" class="mt-5 text-center">
          <h1>Es wurden keine Suchergebnisse gefunden!</h1>
        </div>

        <table class="table" v-if="loaded && !empty && !firstReq">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">ParcourId</th>
            <th scope="col">Name</th>
            <th scope="col">Erbauer</th>
            <th scope="col">Geschafft von</th>
            <th scope="col">Veröffentlicht</th>
            <th scope="col">Spielbar</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in searchItems" v-bind:key="item.parcourId">
              <VerifiedItem :item="item" :index="index"></VerifiedItem>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "debounce";
import Loader from "../components/Loader";
import {env} from "@/environment/env";
import VerifiedItem from "@/components/VerifiedItem";

export default {
  name: "Search",
  components: {VerifiedItem, Loader},
  data() {
    return {
      searchVar: '',
      searchItems: [],
      allowNextRequest: 0,
      buttonState: false,
      loaded: true,
      empty: false,
      firstReq: true
    }
  },
  methods: {
    search: debounce(function (e) {
      this.searchVar = e.target.value;
      this.sendRequest(e.target.value);
    }, 450),
    sendRequest() {
      this.allowNextRequest = (new Date).getTime() + 450;
      this.firstReq = false;
      if ((new Date).getTime() <= this.allowNextRequest) {
        if (this.searchVar) {
          this.loaded = false;
          this.empty = false;
          const route = env.TIMOLIA_API_URL + '/jumpworld/search/verified/' + this.searchVar + '/';
          this.buttonState = true;
          this.enableButton(440);
          fetch(route, { method: 'GET', redirect: 'follow'})
            .then(response => response.json())
            .then((data) => {
              this.searchItems = data.results;
              this.loaded = true;
              if (data.results.length === 0) {
                this.empty = true;
              }
            }).catch(() => {
            this.loaded = true;
            this.empty = true;
          })
        } else {
          this.searchItems = {};
          this.empty = true;
        }
      }
    },
    enableButton(timeout) {
      setTimeout(function () {
        this.buttonState = false;
      }.bind(this), timeout)
    }
  }
}
</script>

<style scoped>
</style>
