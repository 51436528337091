<template>
      <td>{{ index + 1}}</td>
      <td><router-link class="jnrLink" target="_blank" :to="'/jnr/' + item.parcourShortId">{{ item.parcourShortId }}</router-link></td>
      <td>{{ item.title ?? '-'}}</td>
      <td><span class="badge bg-primary" v-bind:style="{ color: item.user.color}">{{item.user.username}}</span></td>
      <td><span class="badge bg-primary">{{ (parseFloat(item.difficulty) * 100).toFixed(2) }}%</span></td>
      <td>{{ formatDate(item.released) }} </td>
      <td>{{ item.deleted === '1' ? '✅' : '❌' }} </td>
</template>

<script>
import Helper from "@/utils/helper";

export default {
  name: "VerifiedItem",
  props: ['item', 'index'],
  methods: {
    formatDate(timestamp) {
      return Helper.formatDate(timestamp);
    },
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
a {
  color: orange;
}

a:hover {
  color: #ff8000;
}
</style>
